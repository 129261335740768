import axios from 'axios';
import setupAxiosInterceptors from 'src/utils/setupAxiosInterceptors';

const fiamAPI = axios.create();

setupAxiosInterceptors(fiamAPI);

fiamAPI.interceptors.request.use((config) => {
  config.baseURL = process.env.FIAM_BASE_URL!;
  config.responseType = 'json';
  config.headers.Authorization = `Bearer ${sessionStorage.getItem(
    'accessToken'
  )}`;

  if (!config.headers['x-site-context']) {
    config.headers['x-site-context'] = `{"account": "${sessionStorage.getItem(
      'accountId'
    )}"}`;
  }
  config.headers.Accept = 'application/json';
  return config;
});

export default fiamAPI;
