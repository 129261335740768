import axios from 'axios';
import { USE_EKS_URL_FF } from 'src/lib/constants';
import { unleash } from './proxyApiClient';

const identityV2API = axios.create();

export const constructBaseUrl = async () => {
  const isEURegion = process.env.IS_EU_REGION === 'true';
  let baseUrl = '';
  const isLive = process.env.stage === 'prod01';
  if (isEURegion) {
    baseUrl = !isLive
      ? 'https://eu.identity-sbx.fabric.inc'
      : 'https://live.eu.identity.fabric.inc';
  } else {
    const useEksUrl = unleash.isEnabled(USE_EKS_URL_FF);
    if (useEksUrl) {
      baseUrl = process.env.IDENTITY_BASE_URL;
    } else {
      baseUrl = !isLive
        ? `https://api.identity-${process.env.apiStage}.fabric.zone`
        : 'https://api.identity.fabric.zone';
    }
  }
  return baseUrl;
};

identityV2API.interceptors.request.use(async (config) => {
  config.baseURL = await constructBaseUrl();
  config.responseType = 'json';
  config.headers.Authorization = `Bearer ${sessionStorage.getItem(
    'accessToken'
  )}`;

  if (!config.headers['x-site-context']) {
    config.headers['x-site-context'] = `{"account": "${sessionStorage.getItem(
      'accountId'
    )}"}`;
  }
  config.headers.Accept = 'application/json';
  return config;
});

export default identityV2API;
