import { Observable } from 'rxjs';
export interface DataFromChatbot {
  data: Record<string, any> | string;
}

export interface DataToChatbot {
  data: {
    message: string;
    data: Record<string, any>;
  };
}

export interface PublishConfig {
  log?: boolean; // Enable or disable logging
}

export abstract class PubSubService {
  constructor() {
    if (new.target === PubSubService) {
      throw new TypeError('Cannot construct PubSubService instances directly');
    }
  }

  // Publish data from chatbot to the domain application
  abstract publishDataFromChatbot(
    data: DataFromChatbot,
    config?: PublishConfig
  ): void;

  // Receive the latest data received from the chatbot
  abstract receiveDataFromChatbot(): Observable<DataFromChatbot>;

  // Publish data from the domain application to chatbot
  abstract publishDataToChatbot(
    data: DataToChatbot,
    config?: PublishConfig
  ): void;

  // Receive the latest data from the domain application
  abstract receiveDataToChatbot(): Observable<DataToChatbot>;
}
