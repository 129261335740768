import axios from 'axios';

const identityLabelsClient = axios.create();

export const constructBaseUrl = () => {
  const isEURegion = process.env.IS_EU_REGION === 'true';
  let baseUrl = '';
  const isLive = process.env.stage === 'prod01';
  if (isEURegion) {
    baseUrl = !isLive
      ? 'https://eu.identity-sbx.fabric.inc'
      : 'https://live.eu.identity.fabric.inc';
  } else {
    baseUrl = process.env.IDENTITY_LABELS_URL;
  }
  return baseUrl;
};

identityLabelsClient.interceptors.request.use((config) => {
  config.baseURL = constructBaseUrl();
  config.responseType = 'json';
  config.headers.Authorization = `Bearer ${sessionStorage.getItem(
    'accessToken'
  )}`;

  if (!config.headers['x-site-context']) {
    config.headers['x-site-context'] = `{"account": "${sessionStorage.getItem(
      'accountId'
    )}"}`;
  }
  config.headers.Accept = 'application/json';
  return config;
});

export default identityLabelsClient;
