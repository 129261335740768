import axios from 'axios';

const identityV2API = axios.create();

export const constructBaseUrl = () => {
  const isEURegion = process.env.IS_EU_REGION === 'true';
  let baseUrl = '';
  const isLive = process.env.stage === 'prod01';
  if (isEURegion) {
    baseUrl = !isLive ?
      'https://eu.identity-sbx.fabric.inc' :
      'https://live.eu.identity.fabric.inc';
  } else {
    baseUrl = process.env.IDENTITY_BASE_URL;
  }
  return baseUrl;
};

identityV2API.interceptors.request.use((config) => {
  config.baseURL = constructBaseUrl();
  config.responseType = 'json';
  config.headers.Authorization = `Bearer ${sessionStorage.getItem(
    'accessToken',
  )}`;

  if (!config.headers['x-site-context']) {
    config.headers['x-site-context'] = `{"account": "${sessionStorage.getItem('accountId')}"}`;
  }
  config.headers.Accept = 'application/json';
  return config;
});

export default identityV2API;
