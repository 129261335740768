import {
  CommerceGPTConversationGroupType,
  CommerceGPTMessageType,
} from 'src/controllers/commerce-gpt';

type Modify<T, R> = Omit<T, keyof R> & R;

export interface ErrorResponse {
  error: {
    statusCode: number;
    message: string;
  };
}

export interface DeletedAddressResponse {
  type: string;
  message: string;
}

export interface PriceList {
  _id?: string;
  isDefault: boolean;
  deleted?: boolean;
  name: string;
  currency: string;
  priceListId: number;
  startDate?: string | null;
  endDate?: string | null;
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
}
export interface DeletedPriceList extends PriceList {
  deleted: boolean;
}

export interface DeletedPriceListResponse {
  data: DeletedPriceList;
}

export interface Pricing {
  id: string;
  name: string;
  currency: string;
  createdAt: string;
  startDateTime: string;
  endDateTime: string;
  default: boolean;
}
export interface PriceListRequestQuery {
  limit: number;
  count: number;
  offset: number;
}

export interface PriceListRequest {
  limit?: number;
  offset?: number;
  pageSize: number;
}

export interface CreatePriceListRequest {
  name: string;
  currency: string;
  isDefault: boolean;
  startDate?: string;
  endDate?: string;
  deleted: boolean;
}

export interface CreatePriceListResponse {
  data: {
    isDefault: boolean;
    deleted: boolean;
    _id: string;
    name: string;
    currency: string;
    startDate: string;
    endDate: string;
    priceListId: number;
    createdAt: string;
    updatedAt: string;
  };
}

export interface PriceListResponse {
  query: PriceListRequestQuery;
  data: PriceList[];
}

export interface SKUWarning {
  sku: string;
  priceListId: number;
  productTitle: string;
  price: number;
  createdAt: Date;
  startDate: string;
  endDate: string;
  status: string;
}

export interface SKUPrice {
  sku: string;
  priceListId: number;
  productTitle: string;
  price: number;
  createdAt: Date;
  startDate: string;
  endDate: string;
  status: string;
}

export interface SKUWarningRequest {
  data: SKUWarning[];
}

export interface SKUPriceListResponse {
  data: SKUPrice[];
  total: number;
}

export interface CurrenciesRequest {
  id: number;
  name: string;
}

export interface CreateSkuPriceRequest {
  priceList: Array<string>;
  sku: Array<string>;
  startDate?: string;
  endDate?: string;
  base: number;
  priceMethodType: string;
  channel?: Array<string>;
  date?: Date;
  createdBy?: string;
  priceParams: Array<{
    minValue: number;
    maxValue: string;
    price: number;
    width: Array<{
      minValue: number;
      maxValue: number;
      price: number;
    }>;
  }>;
  priceAttributes?: Array<{}>;
}

export interface UpdateSkuPriceRequest {
  priceList: string;
  sku: string;
  startDate?: string;
  endDate?: string;
  base: number;
  priceMethodType: string;
  channel?: Array<string>;
  date?: Date;
  createdBy?: string;
  priceParams: Array<{
    minValue: number;
    maxValue: string;
    price: number;
    width: Array<{
      minValue: number;
      maxValue: number;
      price: number;
    }>;
  }>;
  priceAttributes?: Array<{}>;
}
interface Price {
  base: number;
  sale: number;
  cost: number;
  currency: string;
}

export interface PriceKindCreateSKUPriceRequest {
  kind: number;
  channel: number;
  startDate: string;
  endDate: string;
  price: Price;
}

export interface SKUPriceRequest {
  priceListId?: string;
  itemIds: number[];
  itemSkus?: string[];
  offers: PriceKindCreateSKUPriceRequest[];
}

export interface GetBySku {
  priceList: Array<string>;
  sku: Array<string>;
  channel?: Array<string>;
  date?: Date;
  priceMethodType: string;
  priceParams: Array<{
    sku: string;
    quantity: number;
    options: {};
    auditRequired: boolean;
  }>;
}

export interface CreateSkuPriceResponse {
  data: CreateSkuPriceRequest[];
}

export interface UpdateSkuPriceResponse {
  data: UpdateSkuPriceRequest;
}

export interface GetBySkuResponse {
  data: Array<{
    priceList: string;
    sku: string;
    offers: {
      price: {
        sale: number;
        cost: number;
        currency: string;
        base: number;
        isSale: boolean;
        finalPrice: number;
      };
      channel: number;
      kind: string;
      offerCode: Array<[]>;
    };
    audit: {
      userId: string;
      batchProcessId: string;
      priceChangeDate: string;
      priceMethodType: string;
      quantity: number;
      options: {};
    };
  }>;
}

export interface AddSku {
  _id: string;
  sku: string;
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface FeatureFlagRequest {
  projectId: number;
  featureFlagName: string;
}

export interface FeatureFlagResponse {
  name: string;
  active: boolean;
  version?: string;
  strategies?: [];
  error?: boolean;
}

export interface UserData {
  _id?: string;
  name: {
    first: string;
    last: string;
  };
  email: string;
  roles?: Array<string>;
  permissions?: Array<string>;
}

export interface UserListRequest {
  limit: number;
  offset: number;
  pageSize?: number;
  data?: Record<string, unknown>;
  abac?: boolean;
}

export interface UpdateUserResponse {
  id: string;
  status: string;
  orgId?: string;
  loginId: string;
  type: string;
  email: string;
  primaryContact: string | null;
  firstName: string;
  lastName: string;
  meta: string | null;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  organizationId?: string;
}

export interface UpdateUserRequest {
  status?: string;
  orgId?: string;
  loginId?: string;
  type?: string;
  email?: string;
  primaryContact?: string | null;
  firstName?: string;
  lastName?: string;
  organizationId?: string;
}

export interface RoleV2 {
  id: string;
  name: string;
}

export interface UsersV2 {
  [key: string]: {
    name: string;
    email: string;
    status: string;
    createdAt: string;
    roles: Array<RoleV2>;
  };
}

export interface UserListResponse {
  users: Array<any> | UsersV2;
  query?: any;
}

export interface AddSkuRequest {
  sku: string;
}
export interface AddSkuResponse {
  data: AddSku;
}
export interface UpdatePriceListRequest {
  name: string;
  currency: string;
  isDefault: boolean;
  startDate?: string;
  endDate?: string;
  deleted: boolean;
}

export interface PriceListSearchRequest {
  limit: number;
  offset: number;
  pageSize: number;
  priceListId: number;
  keyword: string;
}

export interface PriceListSearchResponse {
  query?: PriceListRequestQuery;
  results: PriceResult[];
}

export interface PriceResult {
  sku: string;
  itemId: number;
  priceStartDate: string;
  priceEndDate: string;
  basePrice: number;
  salePrice: number;
  channel: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  priceRange?: {
    min: number;
    max: number;
  };
  title?: string;
  priceMethodType?: string;
  createdBy?: string;
  images?: Array<string>;
  priceListStartDate?: string;
  priceListEndDate?: string;
  priceListId: number;
}

export interface GetPriceBySkuRequest {
  sku: string;
  priceListId: number;
}

export interface Offer {
  _id?: string;
  kind?: number;
  channel: number;
  startDate?: string;
  endDate?: string;
  price: {
    base: number;
    sale?: number;
    cost?: number;
    currency?: string;
  };
  priceMethodType: string;
  range: Array<{
    _id?: string;
    minQuantity: number;
    maxQuantity: number | 'Infinity';
    price?: number;
    width: Array<{
      minQuantity: number;
      maxQuantity: number | 'Infinity';
      price: number;
    }>;
  }>;
  priceAttributes: Array<Addon>;
  createdBy?: string;
}

export interface GetPriceBySkuResponse {
  _id: string;
  isSoftDeleted: boolean;
  itemId: number;
  itemSku: string;
  offerId: number;
  createdAt: string;
  updatedAt: string;
  priceListId: number;
  offers: Array<Offer>;
}

export type CreateUpdatePriceRangeRequestOffer = Modify<
  Offer,
  {
    priceAttributes: Array<{
      [addonName: string]: number;
    }>;
  }
>;

export interface CreatePriceRangeRequest {
  priceListId: number;
  itemSkus: Array<string>;
  offers: Array<CreateUpdatePriceRangeRequestOffer>;
}

export type CreateUpdatePriceRangeOffer = Modify<
  Offer,
  {
    priceAttributes: Array<{
      attribute: string;
      value: number;
    }>;
  }
>;

export type UpdatePriceRangeResponse = Modify<
  GetPriceBySkuResponse,
  {
    offers: Array<CreateUpdatePriceRangeOffer>;
  }
>;

export type CreatePriceRangeResponse = Array<UpdatePriceRangeResponse>;

export interface InviteUsersRequest {
  senderId: string;
  accountId: string;
  userDetails: Array<{
    email: string;
    firstName: string;
    lastName: string;
    roles: Array<string>;
  }>;
  provider: {
    name: string;
    registrationSite: string;
  };
}

export interface InviteUsersRequestV2 {
  invites: Array<{
    email: string;
    firstName: string;
    middleName: string;
    lastName: string;
    roles: Array<string>;
  }>;
}

export interface InviteUsersResponseV2 {
  code: string;
  message: string;
}

export interface RolePermission {
  _id: string;
  name: string;
  displayText: string;
  description: string;
}

export interface Role {
  _id: string;
  id: string;
  permissions: RolePermission[];
  account: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export type RoleListResponse = Role[];

export interface FetchRolesResponse {
  roles: RoleListResponse;
}

export interface InviteUsersResponse {
  invitedUsers: Array<{
    token_id?: string;
    user?: {
      id: string;
      email: string;
    };
  }>;
  existingUsers: Array<string>;
}

export interface GetAddonsRequest {
  limit?: number;
  offset?: number;
}

export interface Addon {
  priceAddonId: number;
  name: string;
  currency: string;
  price: number;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
}

export interface GetAddonsResponse {
  query: {
    limit: number;
    count: number;
    offset: number;
  };
  data: Array<Addon>;
}

export interface EnabledUser {
  status: string;
  _id: string;
}

export interface EnableDisableRequest {
  action: string;
  userId: string;
}

export interface ResendInviteRequest {
  userId: string;
  senderId: string;
}

type NeededStatusType = 'DRAFT' | 'INACTIVE' | 'ACTIVE';

type shortListType = 'desc' | 'asc';
export interface ListContractsRequest {
  offset: number;
  limit: number;
  sortOrder?: shortListType;
  sortBy?: string;
  startDateFrom?: string;
  startDateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  search?: string;
  status?: Array<NeededStatusType>;
}

export interface ListContractsResponse {
  query: {
    count: number;
    limit: number;
    offset: number;
  };
  data: Partial<Contract>[];
}

export interface CreateContractRequest {
  partyId: string;
  status: string;
  startDate: string;
  endDate: string;
  promotionStacking?: boolean;
  onContract?: boolean;
  priceListId?: string;
  additionalAttributes?: Partial<ContractAdditionalAttributes>;
}

export interface EditContractRequest {
  data: CreateContractRequest;
  contractId: string;
}

export interface ListCustomersRequest {
  offset: number;
  limit: number;
  sortOrder?: shortListType;
  sortBy?: string;
  name?: string;
  traitName?: string;
  isActive?: boolean;
}

export interface SearchCustomerRequest {
  offset: number;
  limit: number;
  sort?: string;
  match?: {
    [id: string]:
      | {
          op: string;
          value: string | number;
        }
      | boolean;
  };
}

export interface ListCustomersResponse {
  query: {
    count: number;
    limit: number;
    offset: number;
  };
  data: Partial<Customer>[];
}

export interface ListCustomerAddressesRequest {
  offset: number;
  limit: number;
  sortOrder?: shortListType;
  sortBy?: string;
  type?: string;
  addressLine1?: string;
  city?: string;
  country?: string;
  postalCode?: string;
}

export interface ListCustomerAddressesResponse {
  query: {
    count: number;
    limit: number;
    offset: number;
  };
  data: Partial<CustomerAddress>[];
}

export interface ListTraitsRequest {
  offset: number;
  limit: number;
  sortOrder?: shortListType;
  sortBy?: string;
  name?: string;
  traitName?: string;
  isActive?: boolean;
}

export interface ListTraitsResponse {
  query: {
    count: number;
    limit: number;
    offset: number;
  };
  data: Partial<Trait>[];
}

export interface CreatePartyTraitParams {
  _id?: string;
  partyId: string;
  traitId: string;
  traitScore?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface DeletePartyTraitParams {
  partyId: string;
  traitId: string;
}

export interface DeletePartyTraitResponse {
  message: string;
}

export interface Party {
  _id: string;
  isDeleted: boolean;
  name: string;
  email: string;
  partyType: string;
  parentPartyId: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  additionalAttributes: Partial<{
    customerContactId: string;
    globalCustomerId: number;
    globalAddressId: number;
    customerType: string;
    parentGlobalCustomerId: number;
    categoryHierarchyId: number;
    salesRepAssignDt: string;
    storeNo: string;
    salesRepDivision: string;
    primarySalesRep: string;
    secondarySalesRep: string;
    taxId: number;
    dunsNumber: number;
    isTaxExempt: boolean;
    taxExemptionId: number;
    webPurchasableInd: number;
    creditRepId: number;
    collectionRepId: number;
    deductionManagerId: number;
    taxExemptionStartDate: string;
    taxExemptionEndDate: string;
    contactType: string;
    phoneNumber: string;
    phoneNumberExt: string;
    faxNumber: string;
    justTesting: string;
    tmkSomething: string;
  }>;
}

export interface Contract {
  _id: string;
  contractId: string;
  partyId: string;
  addressId?: string;
  accountId?: string;
  contractType: string;
  status: string;
  startDate: string;
  endDate: string;
  promotionStacking?: boolean;
  onContract?: boolean;
  additionalAttributes: Partial<ContractAdditionalAttributes>;
  party: Party;
  priceLists?: PriceList[];
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface Trait {
  _id?: string;
  isDeleted?: boolean;
  traitId: string;
  name: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface CustomerAddress {
  _id: string;
  globalAddressId: number;
  type: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  county: string;
  city: string;
  state: string;
  zipCode: number;
  postalCode: string;
  country: string;
  latitude: number;
  longitude: number;
  groupId: string;
  additionalAttributes: unknown;
  isDeleted: boolean;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface CustomerAddressV3 {
  id: string;
  address: Partial<CustomerAddress>;
  additionalAttributes?: {
    [id: string]: string;
  };
  isDeleted: boolean;
  isDefault: boolean;
  deletedAt?: string;
  createdAt: string;
  updatedAt: string;
}

export interface CustomerAdditionalAttributes {
  _id: string;
}

interface NameV3 {
  title?: string;
  firstName: string;
  middleName?: string;
  lastName?: string;
  suffix?: string;
}

interface PhoneV3 {
  number: string;
  type: string;
}

export interface CustomerV3 {
  id: string;
  status: 'ACTIVE' | 'INACTIVE';
  emailAddress: string;
  externalId?: string;
  isDeleted: boolean;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
  additionalAttributes?: {
    [id: string]: string;
  };
  name: NameV3;
  phone: PhoneV3;
}

export interface Customer {
  _id: string;
  parentPartyId: string;
  internalPartyId: string;
  name: string;
  partyType: string;
  isActive: boolean;
  email: string;
  additionalAttributes: Partial<CustomerAdditionalAttributes>;
  isDeleted: boolean;
  deletedOn: string;
  createdAt: string;
  updatedAt: string;
  traits: Partial<Trait>[];
  defaultAddress: Partial<CustomerAddress>[];
}

export interface User {
  _id?: string;
  username: string;
  userId: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  primaryPhone: string;
  status: string;
  role: string;
  additionalAttributes: any;
  createdAt?: string;
  updatedAt?: string;
  isDeleted?: boolean;
}

export interface UserPartyRequest {
  partyId: string;
  userId: string;
}

export interface UserPartyResponse {
  _id: string;
  partyId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
}

export interface ContractAdditionalAttributes {
  globalCustomerId: number;
  divisionCode: string;
  creditLimit: number;
  paymentTermsCode: string;
  freightHandleCode: string;
  discountCode: string;
  rebateCode: string;
  billingMethodCode: string;
  paymentMethodCode: string;
  latePaymentInterestCode: string;
  deliveryMethodCode: string;
  clientPayInd: number;
  creditHoldExemptInd: number;
  creditMessage: string;
  printMessage: string;
  holdOrderCode: string;
  backordersAllowedInd: number;
  billCompleteInd: number;
  deliveryScheduleCode: string;
  deliveryInstructions: string;
  customerNotes: string;
  truckRouteCode: string;
  currencyCode: string;
  mogLocked: any;
}

export interface ContractViewRequest {
  contractId: string;
}

type additionalAttributesTypes = number | string | boolean;
export interface Organization {
  _id: string;
  partyType: string;
  parentPartyId: string;
  isActive: boolean;
  name: string;
  email: string;
  additionalAttributes: Partial<{
    [key: string]: additionalAttributesTypes;
    globalCustomerId: number | string;
    globalAddressId: number | string;
    divisionCode: string;
    custType: string;
    parentGlobalCustomerId: number | string;
    categoryHierarchyId: string | number;
    salesRepAssignDt: string;
    contactType: string;
  }>;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface ListOrganizationResponse {
  query: {
    count: number;
    limit: number;
    offset: number;
  };
  data: Partial<Organization>[];
}

type orgpartyType = 'O' | 'H';
export interface ListOrganizationRequest {
  offset: number;
  limit: number;
  sortOrder?: shortListType;
  sortBy?: string;
  partyType?: orgpartyType;
  id?: string;
  name?: string;
  isActive?: boolean;
}

export const OrganizationUserStatusList = [
  'PENDING',
  'INACTIVE',
  'ACTIVE',
] as const;
type UserStatusType = typeof OrganizationUserStatusList[number];

interface OrganizationUserAdditionalAttributes {
  userId: string;
  globalCustomerId: string;
  userType: string;
  secondaryPhone: string;
  isAdmin: boolean;
  isOwner: boolean;
  canPlaceOrder: boolean;
  canSeePrice: boolean;
  canViewCatalog: boolean;
  canViewOrderHistory: boolean;
  userUuid: string;
}
export interface OrganizationUser extends OrganizationUserAdditionalAttributes {
  _id: string;
  username: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  primaryPhone: string;
  status: UserStatusType;
  role: string;
  groupId: string;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}
export interface OrganizationUserViewRequest {
  userId: string;
  organizationId: string;
}

interface CreateEditOrganizationCommonProperties {
  username: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  primaryPhone?: string;
  status?: UserStatusType;
  role?: string;
  additionalAttributes?: Partial<OrganizationUserAdditionalAttributes>;
  groupId?: string;
}

export interface CreateOrganizationUserRequest
  extends CreateEditOrganizationCommonProperties {
  organizationId: string;
}

export interface EditOrganizationUserRequest
  extends CreateOrganizationUserRequest {
  userId: string;
  organizationId: string;
}

export interface EditOrganizationUserResponse
  extends CreateOrganizationUserRequest {
  userId: string;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface CreateOrganizationUserResponse
  extends CreateEditOrganizationCommonProperties {
  userId: string;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface DeleteOrganizationUserRequest {
  userId: string;
  organizationId: string;
}

export interface DeleteOrganizationUserResponse {
  message: string;
}

export interface ListOrganizationUserRequest {
  offset: number;
  limit: number;
  sortOrder?: shortListType;
  sortBy?: string;
  search?: string;
  type?: string;
  organizationId?: string;
}
export interface ListOrganizationUserResponse {
  query: {
    count: number;
    limit: number;
    offset: number;
  };
  list: Partial<OrganizationUser>[];
}

type orgaddrType = 'M' | 'E' | 'P';
export interface ListOrganizationAddressRequest {
  offset?: number;
  limit?: number;
  sortOrder: shortListType;
  sortBy: string;
  type?: orgaddrType;
  id?: string;
  addressLine1?: string;
  city?: string;
  country?: string;
  postalCode?: string;
}

export interface OrganizationAddressViewRequest {
  orgAddrId: string;
  organizationId: string;
}

export interface OrganizationAddress {
  _id: string;
  type: orgaddrType;
  globalAddressId: number;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  county: string;
  city: string;
  state: string;
  zipCode: string;
  postalCode: string;
  country: string;
  latitude: string;
  longitude: string;
  groupId: string;
  [key: string]: any;
  additionalAttributes: Partial<{
    [key: string]: additionalAttributesTypes;
    zipPlus5: string;
    busOrResFlag: string;
    hadLoadingDock: string;
  }>;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface ListOrganizationAddressResponse {
  query: {
    count: number;
    limit: number;
    offset: number;
  };
  data: Partial<OrganizationAddress>[];
}

export const OrganizationGroupTypeList = [
  'Department',
  'Department',
  'Subsidiary',
  'Business Unit',
  'Team',
  'User',
  'Customer',
] as const;
type OrganizationGroupType = typeof OrganizationGroupTypeList[number];
export interface OrganizationGroupCreateRequest {
  groupType: OrganizationGroupType;
  name: string;
  email: string;
  primaryPhone?: string;
  partyId?: string;
  additionalAttributes: Partial<{
    [key: string]: additionalAttributesTypes;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    streetAddress: string;
  }>;
}

export interface OrganizationGroupUpdateRequest
  extends OrganizationGroupCreateRequest {
  groupId: string;
}

export interface OrganizationGroup extends OrganizationGroupCreateRequest {
  _id: string;
  createdAt: string;
  updatedAt: string;
  __v?: number;
  isDeleted: boolean;
}

export const OrganizationGroupSortByList = [
  'updatedAt',
  'groupType',
  'email',
  'name',
] as const;
type organizationGroupSortBy = typeof OrganizationGroupSortByList[number];
export interface ListOrganizationGroupRequest {
  offset: number;
  limit: number;
  sortOrder?: shortListType;
  sortBy?: organizationGroupSortBy;
  search?: string;
}

export interface ListOrganizationGroupResponse {
  query: {
    count: number;
    limit: number;
    offset: number;
  };
  data: Partial<OrganizationGroup>[];
}

export interface ViewOrganizationGroupRequest {
  organizationId: string;
  groupId: string;
}

export interface ListOrganizationGroupUsersAndAddressRequest
  extends ListOrganizationUserRequest {
  groupId: string;
}
export interface OrganizationRequest {
  name: string;
  partyType: string;
  email: string;
  isActive: boolean;
  internalPartyId?: string;
  additionalAttributes: Partial<{
    [key: string]: additionalAttributesTypes;
    onContract: boolean;
    promotionStacking: boolean;
    randomProperty: string;
    customerContactId: string;
    globalCustomerId: string;
    storeNo: number;
  }>;
}

interface ErrorReponse {
  code?: string;
  message?: string;
}

export interface OrganizationResponse extends ErrorReponse {
  isDeleted: boolean;
  _id: string;
  name: string;
  partyType: string;
  email: string;
  isActive: boolean;
  internalPartyId?: string;
  additionalAttributes: Partial<{
    [key: string]: additionalAttributesTypes;
    onContract: boolean;
    promotionStacking: boolean;
    randomProperty: string;
    customerContactId: string;
    globalCustomerId: string;
    storeNo: number;
  }>;
  createdAt: string;
  updatedAt: string;
}

export interface ViewOrganizationRequest {
  organizationId: string;
}

export interface RoleV2Permissions {
  id: string;
  status: string;
  accountId: string;
  name: string;
  description: string;
  code: string;
  product: string;
  version: string;
  resource: string;
  action: string;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
}
export interface RoleV2Policies {
  id: string;
  status: string;
  accountId: string;
  name: string;
  description: string;
  permissions: RoleV2Permissions[];
  conditions: Array<{}>;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
}

export interface RoleV2 {
  id: string;
  status: string;
  accountId: string;
  name: string;
  description: string;
  policies: RoleV2Policies[];
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
}

export type RoleListV2Response = RoleV2[];

export interface FetchRolesV2Response {
  roles: RoleListV2Response;
}

export interface IdentityV2Response {
  code: string;
  message: string;
}

export interface AddRoleToUserRequest {
  userId: string;
  roleId: string;
}

export interface RemoveRoleToUserRequest {
  userId: string;
  roleId: string;
}

export interface UpdateUserRolesRequest {
  userId: string;
  roles: string[];
}

export interface UpdateUserRolesRequestWithLabels {
  userId: string;
  data: any;
}
export interface AddRoleToUserResponse {
  status: string;
  accountId: string;
  tenantId: string;
  userId: string;
  roleId: string;
  userinfo?: any;
  roles?: any[];
}

export interface RemoveRoleToUserResponse {
  status: string;
  accountId: string;
  tenantId: string;
  userId: string;
  roleId: string;
  userinfo?: any;
  roles?: any[];
}

export interface UpdatePasswordV2Request {
  oldPassword: string;
  newPassword: string;
}

export interface UpdatePasswordV2Response {
  code?: string;
  message?: string;
}

export interface AnalyticsReportRequest {
  customerId: string;
  domain: string;
  status: string;
  type: string;
  offset: number;
  limit: number;
}

export interface AnalyticsReportResponse {
  query: AnalyticsReportRequest;
  reports: {
    service: string;
    dashboardId: string;
    updatedAt: string;
    status: string;
    account: string;
    widgetId: string;
    title: string;
    desc: string;
    type: string;
    jaql?: any;
  }[];
}

export interface JWTRequest {
  accessToken: string;
}

export interface JWTResponse {
  sisense: {
    jwt: string;
  };
}
export interface TenantOrganizationResponse {
  id: string;
  name: string;
  products: Array<string>;
  type: string;
  adminEmail: string;
  emailDomain: string;
  ingressUrl?: string;
}

export type SalesDashboardApiAuthResponseDto = {
  token: string;
  charts: {
    // eslint-disable-next-line camelcase
    number_chart: Array<ChartDetails>;
    // eslint-disable-next-line camelcase
    trend_chart: Array<ChartDetails>;
  };
};

export type ChartDetails = {
  id: string;
  metaData: metaData;
};

export type metaData = {
  filters: { [key: string]: number };
};

export interface LabelItem {
  id: string;
  accountId: string;
  createdAt: string;
  deletedAt?: string;
  description?: string;
  entity?: Record<string, any>;
  name?: string;
  status: string;
  updatedAt?: string;
  updatedBy?: string;
}
export interface FetchLabelsResponse {
  labels: LabelItem[];
}

export interface UpdateUserRoleLabelResponse {
  status: string;
  roles?: RoleListV2Response[];
  roleLabels?: LabelItem[];
}

export interface UserPrompt {
  prompt: string;
  userId: string;
  conversationGroup: CommerceGPTConversationGroupType;
  conversationId?: string;
}

export type CommerceGPTAnalysisOption = {
  GOOD: 'good';
  BAD: 'bad';
};

export interface CommerceGPTStatisticObject {
  attribute: string;
  value: CommerceGPTAnalysisOption['GOOD'] | CommerceGPTAnalysisOption['BAD'];
  suggestions: Array<string>;
  reasons: Array<string>;
}

export interface CommerceGPTDataItemObject {
  content: string;
  metadata?: Array<{
    [key: string]: unknown;
  }>;
  statistics?: Array<CommerceGPTStatisticObject>;
}

export interface CommerceGPTDataObject {
  title: string;
  description: string;
  type: CommerceGPTMessageType;
  items: Array<CommerceGPTDataItemObject>;
}

export interface CommerceGPTResponse {
  conversationId?: string;
  data: Array<CommerceGPTDataObject>;
}

export interface CommerceGPTStarterChipsResponse {
  title: string;
  description: string;
  type: string;
  items?: Array<unknown>;
}

export interface ChatStreamRequestBody {
  prompt: string;
  userId: string;
  conversationId: string;
  conversationGroup: string;
}

export interface AcknowledgementEntityField {
  path: string;
  alias: string;
  value: string;
}
export interface AcknowledgementRequestEntity {
  entityType: string;
  requiredAIFields: Array<AcknowledgementEntityField>;
}
export interface AcknowledgementRequest {
  conversationId: string;
  userId: string;
  actionStep: string;
  status: string;
  entities: Array<AcknowledgementRequestEntity>;
}

export interface AcknowledgementResponse {
  event: string;
  eventType: string;
  data: Record<string, any>;
}

export type ScopesRequest = {
  status?: string;
  userScopes?: UserScope[];
};

export type UserScope = {
  id?: string;
  scope?: Scope;
  entity?: Entity;
};

export type Entity = {
  type?: string;
  id?: string;
  name?: string;
  description?: string;
};

export type Scope = {
  id?: string;
  name?: string;
  description?: string;
  accountId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  attributes?: Attribute[];
};

export type Attribute = {
  id?: string;
  name?: string;
  value?: string[];
  valueType?: string;
  type?: string;
  domain?: string;
  identifier?: string;
  path?: null;
  description?: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export interface ReplaceUserScopesRequest {
  userId: string;
  payload: ScopesRequest;
}

export interface ReplaceUserScopesResponse {
  status: string;
}
export interface DomainResourcesResponse {
  domain?: string;
  endpoint?: string;
  resources?: string[];
}