import { validate } from 'validate.js';
import axios, { AxiosResponse } from 'axios';
import { constructBaseUrl } from 'src/services/identityV2API';
import { UserData } from '../../../@types';

const LOGIN_V2_ROUTE = '/auth/v2/login';

export function fetchAuthToken() {
  const token = sessionStorage.getItem('accessToken');
  if (token) {
    return token;
  }
  return false;
}

export function fetchLoggedInUser(): UserData {
  const userData = {
    _id: sessionStorage.getItem('user'),
    userId: sessionStorage.getItem('userId'),
    name: {
      first: sessionStorage.getItem('firstName'),
      last: sessionStorage.getItem('lastName'),
    },
    email: sessionStorage.getItem('email'),
  };
  return userData;
}

interface UserCredentials {
  accountId: string;
  username: string;
  password: string;
}

interface UserLoginResponse {
  _id: string;
  account: {
    _id: string;
    accountId: number;
    name: string;
  };
  userId: number;
  roles: Array<string>;
  permissions: Array<string>;
  name: {
    first: string;
    last: string;
  };
  accessToken: string;
  refreshToken: string;
  pimVersion: string;
}

export function validateLogInUser(response: UserLoginResponse) {
  const baseError = validate(response, {
    '_id': { type: 'string', presence: { allowEmpty: false } },
    'account._id': { type: 'string', presence: { allowEmpty: false } },
    'account.accountId': { type: 'number', presence: true },
    'account.name': { type: 'string', presence: true },
    'userId': { type: 'number', presence: { allowEmpty: false } },
    'roles': { type: 'array', presence: { allowEmpty: true } },
    'permissions': { type: 'array', presence: { allowEmpty: true } },
    'name.first': { type: 'string', presence: true },
    'name.last': { type: 'string', presence: true },
    'accessToken': { type: 'string', presence: true },
    'refreshToken': { type: 'string', presence: true },
    'pimVersion': { type: 'string', presence: true },
  });

  if (baseError) {
    throw baseError;
  }

  return true;
}

export async function logInUserService(
  request: UserCredentials
): Promise<UserLoginResponse> {
  const { data }: AxiosResponse = await axios.post(
    '/api-identity/auth/local/login',
    request
  );
  return data;
}

export async function logInUser(
  request: UserCredentials
): Promise<UserLoginResponse> {
  try {
    const response = await logInUserService(request);
    validateLogInUser(response);
    return response;
  } catch (error) {
    return error;
  }
}

export async function logout(): Promise<AxiosResponse<any>> {
  const accessToken = sessionStorage.getItem('accessToken');
  const sessionId = sessionStorage.getItem('sessionId');
  const baseUrl = await constructBaseUrl();
  try {
    const response = await axios.post(
      `${baseUrl}/ums/v2/logout`,
      new URLSearchParams({
        token: accessToken,
      }),
      {
        headers: {
          'x-fabric-session-id': sessionId,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function logoutV2(): Promise<AxiosResponse<any>> {
  const accessToken = sessionStorage.getItem('accessToken');
  const sessionId = sessionStorage.getItem('sessionId');
  const baseUrl = await constructBaseUrl();
  try {
    const response = await axios.post(
      `${baseUrl}/ums/v2/logout`,
      new URLSearchParams({
        token: accessToken,
      }),
      {
        headers: {
          'x-fabric-session-id': sessionId,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const logoutRedirectUrl = window.sessionStorage.getItem(
      'logoutRedirectUrl'
    );
    window.sessionStorage.clear();
    window.location.replace(
      logoutRedirectUrl !== 'null' ? logoutRedirectUrl : LOGIN_V2_ROUTE
    );
  } catch (error) {
    return error;
  }
}
