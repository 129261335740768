export const SESSION_STORAGE_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  ACCOUNT: 'account',
  ACCOUNT_ID: 'accountId',
};

export const LOCAL_STORAGE_KEYS = {
  IS_V2_LOGIN: 'isLoggedInWithIdV2',
};

export const USE_EKS_URL_FF = 'copilot-eks-url';
